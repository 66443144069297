import * as React from 'react';
import { Card } from '../../components/Card';
import { Email } from '../../components/Email';

export const Order: React.FC = () =>
<main>
  <Card 
    body="Let us make the perfect cake for your special occasion. Please email us the details of the cake that you have in mind and we can make it a reality."
  >
    <div vocab="http://schema.org/" typeof="OnlineStore">
      <h3><span property="name">Lemon Tree Cakes</span></h3>
      <span property="description">We are a boutique cake business specialising in kids birthday cakes and novelty cakes based in Melbourne Australia.</span>

      <Email/>  
    </div>
  </Card>
</main>
