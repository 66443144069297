import React from "react";
import styles from "./Card.module.css"

interface CardInterface {
  title?: string,
  subtitle?: string,
  body?: string,
  image?: string,
  imageAltText?: string,
  children?: string | JSX.Element | JSX.Element[]
}

export const Card: React.FC<CardInterface> = (card: CardInterface) => {
  return (
    <article className={styles.card}>
      <div>
        {card.title &&
          <h3 className={styles.title}>{card.title}</h3>
        }
        {card.subtitle &&
          <small className={styles.subtitle}>{card.subtitle}</small>
        }
      </div>
      {card.body &&
        <p className={styles.body}>{card.body}</p>
      }  
      {card.image &&
        <img src={card.image} alt={card.imageAltText} className={styles.image} />
      }
      {card.children && card.children}
    </article>
  )
}
export default Card