import type { FC } from "react";
import { Navbar } from './components/Navbar/Navbar';
import { Footer } from "./components/Footer";
import styles from "./App.module.css";

export const App: FC = () => {
  return (
    <div className={styles.app}>
      <Navbar />
      <Footer />
    </div>
  );
};

export default App;