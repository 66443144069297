import React from "react";
import {Link} from "react-router-dom";

type NoMatchProps = {

}

const NoMatch: React.FC<NoMatchProps> = () => {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

export {NoMatch};