import * as React from 'react';
import { Card } from '../../components/Card';
import styles from './Cakes.module.css';

type CakesProps = {

}

export const Cakes: React.FC<CakesProps> = () => 
<main>
  <Card title='Our Cakes'>
    <div className={styles.body}>
      <p>
        Do you need a special sweet treat to celebrate your next event in Melbourne? Lemon Tree Cakes will take the time to discuss your individual needs and create an impressive cake that will taste and look fabulous.     
      </p>
      <p>
        Have a look through our range of kids birthday cakes, novelty cakes, celebration cakes, red velvet cupcakes and talk to us about customising one for your next function.
      </p>
      <p>
        You can place an order for an existing cake design, talk to us about creating a personalised cake or email us your design.  
      </p>

      <h2>Kids Birthday Cakes</h2>
      <p>Enjoy celebrating your childrens special days with custom made cakes. From Thomas the tank Engine to Elmo and Princess cakes and many more. Browse the category and place an order.</p>

      <h2>Novelty Cakes</h2>
      <p>Enjoy the delicious triple layered chocolate mud cakes with amazing designs. Celebrate your birthday in style with a personalised cake made just for you.</p>

      <h2>Celebration Cakes</h2>
      <p>Christening cakes, baby shower cakes, First Birthday cakes and 21st Birthday cakes or a cake for any occasion. Celebration cakes made to order.</p>

      <h2>Cupcakes</h2>
      <p>You and your kids will love these. Choose from a vanilla cupcake, red velvet cupcake or a chocolate cupcake, with either a hard glace or a soft butter cream icing. All you need to decide is how many mini (3 kids bites) and regular (4-5 adult bites) cupcakes you want.</p>
    </div>  
  </Card>
</main>
