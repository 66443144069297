import React from "react";
import { Card } from "../../components/Card";
import { Email } from "../../components/Email";
import styles from "./Faq.module.css";

export const Faq: React.FC = () => 
<main>
  <Card>
    <div className={styles.body}>
      <h2>Quoting for your cake</h2>
      <p>
        When providing a quote for your cake we factor the size, complexity, flavor, materials and technicality. The more detailed and intricate designs will be more than the simple and elegant designs. 
        The labor cost is barely covered in most cakes, as we believe in supplying you with a great looking and a great tasting cake at a reasonable price that you can enjoy.
      </p>

      <h2 id="cake_fillings">Cake Fillings</h2>
      <p>
        Cake fillings vary from cake to cake, chocolate mud cakes and Jaffa cakes have a dark chocolate ganache filling. 
        Caramel mud cakes have white chocolate and caramel filling. You can also select a Chocolate Fudge, 
        Hazelnut Cream, Lemon French Cream or a Vanilla Icing filling.
        These fillings can be flavored with vanilla essence, raspberry, lemon, coconut, espresso, baileys, strawberry, 
        bubblegum, honeycomb, peach, lime, butterscotch, tutti frutti, mango, orange or cinnamon.
      </p>

      <h2 id="cake_sizes">Cake sizes</h2>
      <p>
        As a rough estimate you can use these guidelines as to how many servings you will need. 
      </p>
      <p>
        <i>Round cakes - </i>
        6-inch pan serves 12 slices,
        8-inch pan serves 20 slices,
        10-inch pan serves 36 slices,
        12-inch pan serves 46 slices,
        14-inch pan serves 76 slices.
      </p>
      <p>
        Keep in mind that, these are fairly big slices and if you are serving cake over coffee then these pieces can be cut in half.
        Square cakes give you more pieces, but keep in mind the price for square cakes are higher than the round ones simple because of the difficulty in decorating the edges. 
      </p>
      <p>
        <i>Square cakes - </i>
        6-inch pan serves 18 slices,
        8-inch pan serves 32 slices,
        10-inch pan serves 50 slices,
        12-inch pan serves 72 slices,
        14-inch pan serves 98 slices,
        16-inch pan serves 120 slices,
        18-inch pan serves 140 slices.
      </p>
      <p>
        Again these are guidelines only and if you are serving the cake with coffee these pieces can be cut in half.
        The cakes are 3 inches in height once covered with ganache and fondant which gives you that lovely look.
      </p>

      <h2 id="cake_delivery">Delivery</h2>
      <p>Yes we do deliver within the Eastern, South Easter suburbs and the Melbourne CBD.  Please ask us for details as delivery will incur an additional cost.</p>

      <h2>Cake orders and enquiries</h2>
      <div>
        Send us an email with your query and full contact details. Please note, a deposit is required for orders above $150.
        <Email/>
      </div>
    </div>
  </Card>

</main>
