import * as React from 'react';
import cake from '../../images/cakes/12thBirthdayCake.jpeg';
import styles from "./Home.module.css"
import { Card } from '../../components/Card';

type HomeProps = {
}

export const Home: React.FC<HomeProps> = () =>
<main className={styles.section}>
  <Card 
    title='Cake Artists based in Melbourne Australia'
    body='A boutique cake store that specialises in custom cakes.  Let us create your perfect cake - 
      it will taste and look extraordinary.
      Our cakes are lovingly baked, triple layered, brushed with syrup and filled 
      before being decorated to your custom design.'
    image={cake}
    imageAltText="Girl's 12th Birthday Cake"
  />
</main>