import React, { useState } from "react";
import {
  BrowserRouter,
  NavLink, Outlet, Route, Routes
} from "react-router-dom";
import { Faq } from "../../pages/Faq";
import { Cakes } from "../../pages/Cakes";
import { Home } from "../../pages/Home";
import { Order } from "../../pages/Order";
import { NoMatch } from "../../pages/NoMatch";
import { About } from "../../pages/About";

import "./Navbar.css";
import { SocialIcons } from "../SocialIcons";
import lemonTreeCakesLogo from '../../lemonTreeCakesLogo.svg';

type NavBarProps = {
}

export const Navbar: React.FC<NavBarProps> = () => {
  const [isNavExpanded, setIsNavExpanded] = useState<boolean>(false);

  const toggleMenu = () => {
    setIsNavExpanded(!isNavExpanded);
  }

  const setSelectedColour = (isActive: boolean) => {
    return { color: isActive ? `var(--colours-main)`: `var(--colours-heading-text)` };
  }
  
  return (
    <BrowserRouter>
      <div className="nav-bar">
        {/* A "layout route" is a good place to put markup you want to
            share across all the pages on your site, like navigation. */}
        <nav className="navigation">
          <div>
            <img className="ltc-logo" src={lemonTreeCakesLogo} alt="Lemon Tree Cakes Logo" />
          </div>
          <header className="navigation-header">
            Lemon Tree Cakes
          </header>

          <SocialIcons />
          <button
            className="hamburger"
            onClick={() => {
              setIsNavExpanded(!isNavExpanded);
            }}
          >
            {/* icon from Heroicons.com */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="white"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          <div
            className={
              isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
            }
          >        
            <ul>
              <li>
                <NavLink to="/" style={({ isActive }) => (setSelectedColour(isActive))} onClick={toggleMenu}>Home</NavLink>
              </li>
              <li>
                <NavLink to="/cakes" style={({ isActive }) => (setSelectedColour(isActive))} onClick={toggleMenu}>Cakes</NavLink>
              </li>
              <li>
                <NavLink to="/order" style={({ isActive }) => (setSelectedColour(isActive))} onClick={toggleMenu}>Order</NavLink>
              </li>
              <li>
                <NavLink to="/faq" style={({ isActive }) => (setSelectedColour(isActive))} onClick={toggleMenu}>FAQ</NavLink>
              </li>
              <li>
                <NavLink to="/about" style={({ isActive }) => (setSelectedColour(isActive))} onClick={toggleMenu}>About</NavLink>
              </li>                            
            </ul>
          </div>
        </nav>  

        {/* An <Outlet> renders whatever child route is currently active,
            so you can think about this <Outlet> as a placeholder for
            the child routes we defined above. */}
        <Outlet />
      </div>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/cakes" element={<Cakes />} />
      <Route path="/order" element={<Order />} />
      <Route path="/faq" element={<Faq />} />
      <Route path="/about" element={<About />} />
      <Route path="*" element={<NoMatch />} />
    </Routes>
  </BrowserRouter>
  );

}