import React from "react";
import { Card } from "../../components/Card";
import { Email } from "../../components/Email";

const phone = `${process.env.REACT_APP_LTC_PHONE}`;

export const About: React.FC = () => 
<main>
  <Card 
    body="Hi I'm Udarie, my passion for baking and creative decorating was the starting point for my business. 
    We cater for your special occasions including weddings, birthdays and corporate functions."
  >
    <div vocab="http://schema.org/" typeof="OnlineStore">
      <h3><span property="name">Lemon Tree Cakes</span></h3>
      <span property="description">We are a boutique cake business specialising in kids birthday cakes and novelty cakes based in the </span>
      <span rel="address">
        <span typeof="PostalAddress">
          <span property="addressLocality">Eastern Suburbs, Melbourne, </span>
          <span property="addressRegion">VIC</span>
        </span>
      </span>
      <div>
        Phone: <span property="telephone">{phone}</span>
      </div>
      <Email/>  
    </div>
  </Card>
</main>
