import * as React from 'react';
import styles from './Email.module.css';

const mailto = `mailto:${process.env.REACT_APP_LTC_EMAIL}?subject=${process.env.REACT_APP_LTC_ENQUIRY_SUBJECT}`;

export const Email: React.FC = () =>
<div className={styles.container}>
  <p>
    Email: 
  </p>
  <span property="email">
    <a href={mailto}>
      {process.env.REACT_APP_LTC_EMAIL}
    </a>
  </span>
</div>
